/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-destructuring */
<template>
  <div>
    <VideoSlider />
    <section id="card-images">
      <b-row>
        <b-col
          v-for="item in options"
          :key="item.xid"
          md="5"
          lg="4"
          xl="3"
        >
          <router-link :to="item.slug">
            <b-card
              class="mb-3"
            ><progressive-img
               :src="`https://thumb.brimecdn.com/?channel=6050a4804b90bf0430aec1e2`"
               fallback="https://f004.backblazeb2.com/file/brime-assets/missing_thumbnail.png"
             /><span
               id="streamTitle"
             ><b-avatar
                size="45"
                :src="`https://assets.brimecdn.com/cdn-cgi/image/width=100,quality=100/users/${item.channel_owner_xid}/avatar`"
                variant="light-primary"
                class="badge-minimal mr-1"
              /><span
                id="title"
                style="padding-left: 50px; margin-top: -40px; color: #FFF"
              >{{ item.stream_title }}</span>
               <span
                 id="stream"
                 style="padding-left: 55px;"
               >
                 <strong>{{ item.slug }}</strong><br>
                 <span
                   id="category"
                   style="color: rgba(255, 255, 255, 0.6)"
                 >
                   {{ item.category_name }} </span>
               </span></span>
              <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
              <div class="live__pill">
                LIVE
              </div>
              <div
                v-if="item.bitrate >= 8000"
                class="hd__pill"
              >
                BRIME HD+
              </div>
              <div
                v-if="item.bitrate >= 8000"
                class="fps__pill"
              >
                120 FPS | HD+
              </div>
              <div
                class="viewcount__pill"
              >
                {{ item.viewer_count }} <span v-if="item.viewer_count == 1">Viewer </span> <span v-if="item.viewer_count != 1">Viewers </span>
              </div>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar,
} from 'bootstrap-vue'

import Vue from 'vue'
import VideoSlider from '../../../components/carousel.vue'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
const prefix = 'https://edge.brimelive.com/live/'
const suffix = '/thumbnail.jpg'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    VideoSlider,
  },
  data() {
    return {
      options: [],
      test: {},
      prefix,
      suffix,
      viewcount: {},
      mapped: null,
    }
  },
  async mounted() {
    document.title = 'Live Channels - Brime'
  },
  async beforeCreate() {
    const data = await (await fetch('https://api.brime.tv/v1/channels/live')).json()
    // this.options = data.streams
    // console.log(this.options)
    /* eslint-disable-next-line no-undef */
    // this.viewCount = await (await fetch('https://api-new.brimelive.com/viewers/all')).json()
    // this.mapped = this.viewCount.reduce((cs, c) => {
    //   // eslint-disable-next-line no-param-reassign
    //   cs[c.channelId] = { count: c.count }
    //   return cs
    // }, {})
  },
}
</script>
<style scoped>
/* Scrollbar styles */
.dark-layout .card {
  box-shadow: unset;
 box-shadow: 0px 5px 10px 0 rgb(12 20 39 / 48%);
}
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
}
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}
.card:hover img {
  filter: saturate(1.2)
}

.card img {
  filter: saturate(1);
  transition: all 0.2s;
}
.live__pill {
  z-index: 1;
  position: absolute;
  letter-spacing: 0.35px;
  left: 25px;
  top: 20px;
  padding: 2px 6px;
  background: rgba(252, 53, 55, 0.75);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hd__pill {
  z-index: 0;
  position: absolute;
  letter-spacing: 0.35px;
  left: 25px;
  top: 20px;
  padding: 2px 6px;
  background: linear-gradient(45deg, rgba(132,57,175,1) 0%, rgba(252,53,55,1) 100%);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fps__pill {
  z-index: 1;
  position: absolute;
  letter-spacing: 0.35px;
  left: 25px;
  top: 20px;
  padding: 2px 6px;
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.fps__pill {
    background-image: linear-gradient(178deg, #fc3537, #8439af);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 96%}
    50%{background-position:100% 5%}
    100%{background-position:0% 96%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 96%}
    50%{background-position:100% 5%}
    100%{background-position:0% 96%}
}
@keyframes AnimationName {
    0%{background-position:0% 96%}
    50%{background-position:100% 5%}
    100%{background-position:0% 96%}
}
.viewcount__pill {
  z-index: 1;
  position: absolute;
  letter-spacing: 0.35px;
  right: 23px;
  top: 20px;
  padding: 2px 6px;
  background: rgba(0,0,0,0.75);
  background-filter: blur(10px);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card {
  height: calc(100% - 3rem);
  display: flex;
  background-color: unset;
}

.card-body {
  padding: .75rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#category {
  margin-top: 2px;
  text-align: left;
  display: block;
}
#title {
  margin-left: 5px;
  color: #b4b7bd;
  margin-top: auto;
  text-align: left;
  font-size: 15px;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamtitle {
  font-size: 14px;
  line-height: 18px;
  vertical-align: center;
}
#stream {
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  height: 100%;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamTitle {
  color: #7367f0;
  font-size: 14px;
  display: block;
  text-align: left;
  padding-top: 2px;
}
.badge-minimal{
  transform: translateY(11px);
}
</style>
